import React, { useState, useEffect } from 'react';
import './gradcert.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from "react-router-dom";
import QRCode from 'qrcode.react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import CertificatePDF from './downloadCert';
import { saveAs } from 'file-saver'; // Import the file-saver library
import jsPDF from 'jspdf';

import { JsonToTable } from "react-json-to-table";
import axios from 'axios';
const moment = require('moment');


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:2utZGEuzWe8ds5Jaxd7KdMD7bwKMm8QiRKnS2t4Q8Dve;earthid:testnet:fid=0.0.15057499";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";

const marksData = [
    { subject: "Mathematics", marks: 85 },
    { subject: "Science", marks: 78 },
    { subject: "History", marks: 90 },
  ];
  const attendancePercentage = 95;

  const currentDate = moment().format("MMMM Do YYYY, h:mm:ss A");




const styles = StyleSheet.create({
    certificate: {
      fontFamily: 'Helvetica',
      fontSize: 12,
      textAlign: 'center',
      padding: 30,
      border: '1px solid #ccc',
    },
    header: {
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    title1: {
      fontSize: 14,
      marginBottom: 10,
    },
    body: {
      marginBottom: 10,
    },
    name: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    qrcodeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    signature: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  });


const GradCertificate = () => {
    const [responseData, setResponseData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    //const [showJson, setShowJson] = useState(true);
    const { userName } = useParams();
    const qrCodeUrl = `https://university.myearth.id/certificate/on-boarding/${userName}`;



    useEffect(() => {

      
        const fetchData = async () => {
            try {
                const prefix = 'OnBoardingCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${userName}`;
            console.log('Username', userNamee)
                const cidsResponse = await fetch(`https://w3storage.myearth.id/api/vc/fetchVcCids/${userNamee}`);
                const cidJson = await cidsResponse.json();
                console.log('CidsResponse', cidJson);

                if (cidJson && cidJson.cids && cidJson.cids.length > 0) {
                    const response = await fetch('https://w3storage.myearth.id/api/vc/fetchVCMain', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/vnd.ipld.car',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            cids: [
                                cidJson.cids[cidJson.cids.length - 1]
                            ],
                        }),
                    });

                    if (response.ok) {
                        const json = await response.json();
                        console.log(json);
                        setResponseData(json);
                    } else {
                        console.log('Request failed with status code:', response.status);
                    }
                } else {
                    console.log('Certificate not generated');
                    Swal.fire('Certificate Not Generated');
                }
            } catch (error) {
                console.log('Error:', error);
            }
            finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);





    useEffect(() => {
        if (isButtonClicked && responseData) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true]);
            setVerificationData([]);

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([true, true, true, true, true]);
                setVerificationData([
                    moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                    
                    "EarthID Universty",
                    userName
                ]);

                setTimeout(() => {
                    setIsVerificationLoading([false, true, true, true, true]);
                    setVerificationData([
                        moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                        
                        "EarthID Universty",
                        userName
                    ]);

                    setTimeout(() => {
                        setIsVerificationLoading([false, false, true, true, true]);
                        setVerificationData([
                            moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                            
                            "EarthID Universty",
                            userName
                        ]);

                        setTimeout(() => {
                            setIsVerificationLoading([false, false, false, true, true]);
                            setVerificationData([
                                moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                                
                                "EarthID Universty",
                                userName
                            ]);

                            setTimeout(() => {
                                setIsVerificationLoading([false, false, false, false, true]);
                                setVerificationData([
                                    moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                                    
                                    "EarthID Universty",
                                    userName
                                ]);

                                setTimeout(() => {
                                    setIsVerificationLoading([false, false, false, false, false]);
                                    setVerificationData([
                                        moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                                        
                                        "EarthID Universty",
                                        userName
                                    ]);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 10000);
        }
    }, [isButtonClicked, responseData]);

    //Verify Api

    const createUserSignature = async (documentMetadata) => {
        try {
            const userDid = await createUserDid();
            const payload = {
                payload: {
                    credentialSubject: {
                        id: userDid,
                        pdfMetaData: documentMetadata,
                    },
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    privateKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log(response.data)
            return response.data.Signature;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    const verifyVC = async () => {
        try {

            const signature = await createUserSignature();

            const data = {
                "credentials": {
                    ...responseData[0]
                }
            }
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    'userSignature': signature, // Call the createUserSignature function
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };

    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsVerifying(true);
        setIsButtonClicked(true);

        // Call verifyVC() here
        verifyVC().then((verificationResult) => {
            // Process the verification result and update state variables accordingly
            setIsVerifying(false);
            // Update other state variables based on verification result
            // Example:
            // setIsVerificationLoading([...]);
            // setVerificationData([...]);
        }).catch((error) => {
            // Handle error
            setIsVerifying(false);
            // Display error message or take appropriate action
        });
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    // function toggleView() {
    //     setShowJson(!showJson);
    //   }

    const handleViewCertificate = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Digital Certificate(VC)',
            html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,     
            confirmButtonText: 'Verify',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
            didOpen: () => {
                const verifyButton = MySwal.getConfirmButton();
                verifyButton.addEventListener('click', () => {
                    const loadingSwal = MySwal.mixin({
                        title: 'Loading',
                        text: 'Performing verification...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            loadingSwal.showLoading();
                            verifyVC()
                                .then(() => {
                                    // Display success message upon successful verification
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Successful',
                                        text: '',
                                        icon: 'success',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                })
                                .catch((error) => {
                                    // Handle error case and display error message
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Error',
                                        text: 'An error occurred during verification.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: true,
                                        allowEscapeKey: true,
                                        showCancelButton: false,
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                });
                        },
                    });

                    loadingSwal.fire();
                });
            },
        });
    };



// Function to handle the PDF download
const handleDownload = () => {
    const pdf = new jsPDF();

    const title = 'On-Boarding Certificate';
    const year = 'Year 2023-2024';
    const certificateText = `This certificate verifies that\n${userName} has successfully gone through the document verification and has completed all the on-boarding processes of the university.\nThis certificate is a verifiable credential and is valid till: ${moment(
      responseData[0].expirationDate
    ).format('MMMM Do YYYY, h:mm:ss A')}.`;
    const signature = 'EarthID University';

    const qrCodeSize = 128;
    const qrCodeX = pdf.internal.pageSize.getWidth() - qrCodeSize - 20;
    const qrCodeY = 20;

    // Add content to PDF
    pdf.setFontSize(18);
    pdf.text(title, 20, 30);
    pdf.setFontSize(12);
    pdf.text(year, 20, 45);
    pdf.text(certificateText, 20, 60);
    pdf.setFontSize(14);
    pdf.text(signature, 20, 180);
    pdf.addImage(qrCodeUrl, 'JPEG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

    // Save the PDF as a file
    pdf.save(`certificate_${userName}.pdf`);
  };



    //   const handleViewCertificate = () => {
    //     const MySwal = withReactContent(Swal);

    //     MySwal.fire({
    //       title: 'Digital Certificate(VC)',
    //       html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,
    //       confirmButtonText: 'Close',
    //       customClass: {
    //         content: 'certificate-swal',
    //       },
    //     });
    //   };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {responseData && responseData.length > 0 ? (
                <div className="page-container">
                    <nav className="navbar-cert">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                        <ul className="nav-menu">
                            <li className="nav-item"><a href={`/certificate/on-boarding/${userName}`}>Home</a></li>
                        </ul>
                    </nav>
                    <div className="certificate-container">
                        <section className='certBack'>
                        <div className="transcript">
  <div className="header">
    <div className="favico">
      <img src={favicon} alt="University Logo" />
    </div>
    <div className="title">Student Transcript</div>
  </div>
  <div className="subtitle">Year 2023-2024</div>
  <div className="name">{userName}</div>
  <div className="subject-marks">
    {marksData.map((item, index) => (
      <div key={index} className="subject-row">
        <span className="subject">{item.subject}:</span>
        <span className="marks">{item.marks}</span>
      </div>
    ))}
  </div>
  <div className="attendance">
    <span className="title">Attendance Percentage:</span>
    <span className="percentage">{attendancePercentage}%</span>
  </div>
  <div className="validity">
    This transcript is a verifiable credential and is valid till:{" "}
    {moment(responseData[0].expirationDate).format("MMMM Do YYYY, h:mm:ss A")}.
  </div>
  <div className="qrcode-container">
    {/* Add the QRCode component */}
    <QRCode value={qrCodeUrl} size={128} />
  </div>
  <div className="signature">EarthID University</div>
</div>
                        </section>
                    </div>
                    {/* Add the PDF download button */}
                    {/* Add the download button */}
      {/* <button onClick={handleDownload}>Download PDF</button> */}
                    <section className='infosec'>
                        <div className='container'>
                            <div className='row'>
                                <div className='leftsec col-md-8'>
                                    <div className='row'>
                                        <div className="check col-md-1">
                                            <img src={check}></img>
                                        </div>
                                        <h2 className='coursename col-md-11'>Student On-Boarding Certificate</h2>
                                    </div>

                                    <div className='stuname'>Issued to: {userName}</div>
                                    <div className='stuID'>ID: EarthID</div>
                                    <hr></hr>
                                    <div className='description'>
                                    This certificate confirms that {userName} has successfully completed the student onboarding process at EarthID University. As an officially registered student, VaibhavJulyDemo is hereby granted an official Student ID. 
                                    This ID validates the student's connection with the university and serves as evidence of their enrollment in the designated program.
                                    </div>
                                    <hr></hr>
                                    <div className='row'>
                                        <div className='issueon col-md-6'>
                                            <div>Issued On</div>
                                            <div>{moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                        <div className='expiry col-md-6'>
                                            <div>Expires On</div>
                                            <div>{moment(responseData[0].expirationDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                    </div>
                                    <button className='verify-btnn' onClick={handleViewCertificate}>View Digital Certificate</button>
                                </div>
                                <div className='col-md-4'>
                                    <div className="footer1">
                                        <div className='verify'>Certificate Verification</div>
                                        <div className='verify1'>This certificate is from a verified issuer</div>
                                        <button className="verify-btn" onClick={handlePopupOpen}>
                                            Verify
                                        </button>
                                    </div>
                                    <div className="footer1">
                                        <div className='verify'>More about the Issuer</div>
                                        <div className='verify1'>EarthID University</div>
                                        <button className="verify-btn" onClick={() => window.open('https://myearth.id', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </section>

                    {isPopupOpen && (
                        <div className="popup-container">
                            {isVerifying ? (
                                <div>
                                    <div className="spinner"></div>
                                    <div className="spinner-text">Verifying Credentials...</div>
                                </div>
                            ) : (
                                <div className="popup">
                                    <div className="popup-header">
                                        <div className="popup-title">Verification Details</div>
                                        <button className="popup-close" onClick={handlePopupClose}>
                                            X
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div className="popup-body">
                                        {isVerificationLoading[0] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issuer is Valid:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issuer is Valid:</div>

                                                    <div className="verification-result1">
                                                        <div>The issuer is found to be valid</div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[1] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issued on:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued on:</div>

                                                    <div className="verification-result1">
                                                        {verificationData[0] && <div>{verificationData[0]}</div>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[2] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issued by:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued by:</div>

                                                    <div className="verification-result1">
                                                        {verificationData[1] && <div>{verificationData[1]}</div>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[3] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issued to:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued to:</div>

                                                    <div className="verification-result1">
                                                        {verificationData[2] && <div>{verificationData[2]}</div>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[4] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label"></div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success1"><img src={check}></img></div>
                                                    </div>
                                                    <div className="verification-label"></div>

                                                    <div className="verification-result1">
                                                        <div className='verified'>VERIFIED</div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <footer className="footer">
                        <div className="logo"></div>
                        <div className="footer-text">
                            © 2023 EarthID, Inc. All Rights Reserved.
                        </div>
                    </footer>
                </div>
            ) : (
                Swal.fire({
                    title: "No Certificate Found",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Go Back",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the home page
                        window.location.href = "/uploadDoc";
                    }
                })
            )}
        </>

    );
};

export default GradCertificate;
