import React from 'react'
import "../../App.css";

import {faTwitter, faFacebook, faYoutube, faLinkedinIn, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Baseui = () => {
  return (
    <>
      <section class="home1">
            <div class="container">
              <div class="row">
<div class="col-md-6"></div>
                <div class="col-md-6">
                  <h3>LEARN - EXPLORE - GROW</h3>
                  <hr />
                  <p>Discover new possibilities and opportunities with us.</p>
                  <a class="left1" href="https://www.myearth.id/" target="_blank">Join Now!</a>
                </div>
              </div>
            </div>
          </section>
          <section class="home2">
            <div class="container">
              <h2>We're not just an online course provider, we're a community of learners and experts committed to your growth and success. </h2>
              <div class="row">
                <div class="extra-div col-md-4">
                  <h2>01</h2>
                  <h2>Convenience</h2>
                  <hr/>
                  <h3>Flexible: Learn on your own schedule and at your own pace with our self-paced courses.</h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>02</h2>
                  <h2>Affordability</h2>
                  <hr/>
                  <h3>Accessible: Quality education at a fraction of the cost.</h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>03</h2>
                  <h2>Expertise</h2>
                  <hr/>
                  <h3>Excellence: Learn from industry leaders and subject matter experts.</h3>
                </div>
              </div>
            </div>
          </section>

          <section class="home3">
            <div class="container">
              <div class="col-md-7">
                <h2>Experience Personalized Learning with Our Online Platform</h2>
                <h4>We offer a holistic approach to education that empowers learners to reach their full potential. Our engaging courses, expert instructors, and interactive learning tools provide a dynamic and personalized experience that's tailored to your needs. Say goodbye to traditional classroom constraints and hello to a world of limitless possibilities.</h4>

              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
              </div>
              <div class="types col-md-4">
                <h2>01</h2>
                <h3>Personalization</h3>
                <p>We provide personalized learning experiences, with tailored course recommendations, progress tracking, and feedback to help learners achieve their goals.</p>
              </div>

            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="types1 col-md-4">
                <h2>02</h2>
                <h3>Innovation</h3>
                <p>We leverage cutting-edge technology and pedagogy to create engaging and interactive courses that keep learners motivated and help them retain knowledge.</p>
              </div>
              <div class="col-md-5">
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
              </div>
              <div class="types2 col-md-4">
                <h2>03</h2>
                <h3>Community</h3>
                <p>We foster a vibrant learning community, with opportunities for collaboration, networking, and support from peers, mentors, and instructors.</p>
              </div>

            </div>

          </section>

          <footer class="footerr">
            <div class="container">

              <div class="row">
                <div class="col-md-4">
                  <h2>EarthID
                  <br></br>Start your journey to success today</h2>

                </div>
                <div class="social col-md-8">
                  <h2>Follow Us:</h2>

                  <a href="https://twitter.com/earthid_ssi" target="blank"><FontAwesomeIcon icon={faTwitter} /></a>
                  <a href="https://www.facebook.com/myearth.id/" target="blank"><FontAwesomeIcon icon={faFacebook} /></a>
                  <a href="https://www.linkedin.com/company/myearthid/" target="blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                  <a href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured" target="blank"><FontAwesomeIcon icon={faYoutube} /></a>
                  <a href="https://www.instagram.com/myearthid/" target="blank"><FontAwesomeIcon icon={faInstagram} /></a>
                  <a href="#"><FontAwesomeIcon icon={faEnvelope} /></a>

                </div>
              </div>
            </div>
          </footer>
    </>
  )
}

export default Baseui
